import { Image } from 'react-datocms'
import { useMount } from 'react-use'
import { motion } from 'framer-motion'
import type { InferGetStaticPropsType } from 'next'
import { useRouter } from 'next/router'
import { snake } from 'radash'
import invariant from 'tiny-invariant'

import { Button } from '@lederne-ui/button'
import { Container } from '@lederne-ui/container'
import { Divider } from '@lederne-ui/divider'
import { LederneAndPfaLogo } from '@lederne-ui/logos/lederne-and-pfa-logo'

import { coreQuadrantApi } from '@features/core-quadrant/api'
import { InformationBlocks } from '@features/core-quadrant/components/information-blocks/information-blocks'

import { Layout } from '@components/common/layout'
import { IntroAnimation } from '@components/intro-animation'
import { getAbsoluteUrl } from '@utils/get-absolute-url'
import { getReferrer } from '@utils/get-referrer'
import { gtm } from '@utils/gtm'

const startFlowButtonText = 'Udforsk din kernekvadrant'

export default function HomePage({
  content,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const router = useRouter()

  // Track a Virtual Page View event on mount
  useMount(() => {
    gtm.trackVirtualPageView({
      pageUrl: getAbsoluteUrl(router.asPath),
      pagePath: router.pathname,
    })
  })

  if (router.isReady) {
    router.prefetch('/kernekvadranten')
  }

  const handleStartFlow = async () => {
    // Track the start of the flow.
    gtm.trackEvent({
      event: 'gaEvent',
      eventCategory: 'kernekvadranten',
      eventAction: 'flow_start',
      eventLabel: snake(startFlowButtonText),
      referrer: getReferrer(),
    })

    await router.push('/kernekvadranten?step=cluster')
  }

  return (
    <motion.div
      key={'home-page'}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Layout>
        <div className={'min-h-screen border-[6px] border-white'}>
          <Container>
            <div
              className={
                '3xl:mt-32 mx-auto mt-10 flex justify-center 2xl:mt-20'
              }
            >
              <LederneAndPfaLogo
                aria-hidden={'true'}
                aria-label={'lederne and pfa logos'}
                height={24}
                role={'img'}
              />
            </div>

            <div
              className={
                '3xl:mt-52 pb-10 lg:pb-16 2xl:mt-32 2xl:grid 2xl:grid-cols-2 2xl:gap-10 2xl:pb-0'
              }
            >
              <div className={'hidden max-w-[500px] 2xl:flex'}>
                <IntroAnimation />
              </div>

              <div
                className={
                  'mx-auto mt-14 max-w-[75ch] text-center sm:mt-20 lg:mt-32 2xl:mt-0 2xl:text-left'
                }
              >
                <h1
                  className={
                    'text-3xl font-semibold text-white md:text-4xl lg:text-5xl xl:text-6xl'
                  }
                >
                  {content.topHeading} <br />{' '}
                  <span
                    className={
                      'text-minty-green-500 font-serif font-bold italic'
                    }
                  >
                    {content.topSubheading}
                  </span>
                </h1>

                <div
                  className={
                    'my-6 flex items-center justify-center lg:my-10 2xl:justify-start'
                  }
                >
                  <Divider size={'xlarge'} orientation={'horizontal'} />
                </div>

                <p className={'text-sm md:text-base 2xl:text-lg'}>
                  {content.topDescription}
                </p>

                <div className={'mt-10 block 2xl:hidden'}>
                  <IntroAnimation />
                </div>

                <Button
                  icon={'arrow'}
                  isInline
                  className={'mt-10 xl:mt-4 2xl:hidden'}
                  onPress={handleStartFlow}
                >
                  {startFlowButtonText}
                </Button>

                <Button
                  icon={'arrow'}
                  className={'hidden 2xl:mt-10 2xl:inline-flex'}
                  onPress={handleStartFlow}
                >
                  {startFlowButtonText}
                </Button>
              </div>
            </div>

            <InformationBlocks informationBlocks={content.topInformation} />
          </Container>
        </div>

        <div className={'bg-white'}>
          <Container>
            <div
              className={
                'mx-auto max-w-3xl rounded pb-20 pt-12 text-sm text-black lg:pb-[200px] lg:pt-16'
              }
            >
              <div
                className={'mb-6 flex flex-col gap-6 xl:flex-row xl:items-end'}
              >
                <Image data={content.bottomImage.responsiveImage} />

                <h3 className={'text-2xl font-semibold'}>
                  {content.bottomTitle}
                  <br />{' '}
                  <span
                    className={'text-welcoming-green-800 font-serif italic'}
                  >
                    {content.bottomSubheading}
                  </span>
                </h3>
              </div>

              <div
                className={'prose'}
                dangerouslySetInnerHTML={{ __html: content.bottomDescription }}
              />
            </div>
          </Container>
        </div>
      </Layout>
    </motion.div>
  )
}

export async function getStaticProps() {
  const frontpage = await coreQuadrantApi.getFrontpage()

  invariant(frontpage.data, 'Frontpage is undefined')

  return {
    props: {
      content: frontpage.data,
    },
    revalidate: 10,
  }
}

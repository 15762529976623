import { Image } from 'react-datocms'
import clsx from 'clsx'

import { Button } from '@lederne-ui/button'

import type { InformationBlockSchema } from '@features/core-quadrant/entities/information-block'

type Props = {
  className?: string
  informationBlocks: InformationBlockSchema[]
}

export const InformationBlocks = ({ className, informationBlocks }: Props) => {
  return (
    <div
      className={clsx(
        'mb-16 flex flex-col items-stretch gap-8 2xl:flex-row',
        className,
      )}
    >
      {informationBlocks.map((info) => (
        <div
          key={info.id}
          className={
            'border-minty-green-500 w-full space-y-4 rounded border p-4 2xl:p-8'
          }
        >
          <div className={'h-16 w-16'}>
            <Image data={info.icon.responsiveImage} />
          </div>

          <div className={'space-y-5'}>
            <h3 className={'text-minty-green-500 text-2xl font-semibold'}>
              {info.heading}
            </h3>

            <p>{info.description}</p>

            {info.link || info.file?.url ? (
              <Button
                variant={'text-white'}
                icon={'arrow'}
                iconPosition={'end'}
                size={'small'}
                elementType={'a'}
                href={info.link || info.file?.url}
                target={'_blank'}
                rel={'noreferrer'}
                multiline
              >
                {info.linkText}
              </Button>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  )
}
